import React, { useState } from 'react';
import './contact.css';
import emailjs from 'emailjs-com';

// Main Contact component definition
function Contact() {
    // State to manage form submission status
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Handle the form submission
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevents the default form submit action
         // Prepare the data to be sent in the email
         const formData = new FormData(event.target);
         const emailParams = {
            from_name: formData.get('first_name') + " " + formData.get('last_name'),
            to_name: 'Your Name or Company Name',
            message: formData.get('message'),
            reply_to: formData.get('email'),
            phone_number: formData.get('phone_number'),
            event_date: formData.get('event_date'),
            type_of_event: formData.get('type_of_event'),
            location: formData.get('location'),
            // ...include other form fields as needed
        };

          // Send the email using EmailJS
        emailjs.send('service_d6avb1b', 'template_xu676gp', emailParams, 'eQAgpvHNz3LaAoaw0')
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setIsSubmitted(true); // Updates the state to indicate submission
        }, (error) => {
            console.log('FAILED...', error);
        });
};

// JSX for the Contact component
return (
    <div className="contact-container">
        <h2>Contact Me</h2>
        <div className="card">
            {/* Information section displaying contact details */}
            <div className="info-section">
                <div className="text-container-info">
                    <h2>Contact Information</h2>
                    {/* Contact icons and details */}
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M6.62 10.79C8.06 13.62 10.38 15.94 13.21 17.38L15.41 15.18C15.69 14.9 16.08 14.82 16.43 14.93C17.55 15.3 18.75 15.5 20 15.5C20.2652 15.5 20.5196 15.6054 20.7071 15.7929C20.8946 15.9804 21 16.2348 21 16.5V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21C15.4913 21 11.1673 19.2089 7.97918 16.0208C4.79107 12.8327 3 8.50868 3 4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H7.5C7.76522 3 8.01957 3.10536 8.20711 3.29289C8.39464 3.48043 8.5 3.73478 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z" fill="white"/>
                        </svg> 346-442-1002 </p>
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z" fill="white"/>
                        </svg> MACSPARTYPRODUCTIONS@gmail.com</p>
                </div>
            </div>
            {/* Form section that toggles based on submission status */}
            <div className="form-section">
                {isSubmitted ? (
                    // Confirmation message displayed post-submission
                    <div className="confirmation-message">
                        <p>Thank you for your message! I'll get back to you soon.</p>
                    </div>
                ) : (
                    // The contact form with various input fields
                    <form onSubmit={handleSubmit}>
                        {/* Input fields for name arranged in a row */}
                        <div className="input-row">
                            <input type="text" name="first_name" placeholder="First Name" required />
                            <input type="text" name="last_name" placeholder="Last Name" required />
                        </div>
                        {/* Input fields for contact details and message */}
                        <input type="email" name="email" placeholder="Email" required />
                        <input type="text" name="phone_number" placeholder="Phone Number" required />
                        <input type="date" name="event_date" placeholder="Event Date" />
                        <input type="text" name="type_of_event" placeholder="Type of Event" />
                        <input type="text" name="location" placeholder="Venue/Location" />
                        <textarea name="message" placeholder="Write your message..."></textarea>
                        {/* Submit button for the form */}
                        <button className="mail-btn" type="submit">Send Message</button>
                    </form>
                )}
            </div>
        </div>
    </div>
);
}

export default Contact;