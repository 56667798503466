import React from 'react';
import './home.css';
import logo from '../images/main_logo.png';

// The Home functional component renders the homepage content
function Home() {
    // Render the home section with various content blocks
    return (
        <section id="home">
            {/* Logo container at the top of the page */}
            <div className="logo-container">
                <img src={logo} alt="Mac's Party Productions" />
            </div>
            
            {/* Container for the main content */}
            <div className="home-container" id="about-me">
                {/* Main title for the home page */}
                <h1 className="home-title">Welcome to Mac's Party Productions</h1>

                {/* About section with a description of services */}
                <div className="text-container">
                    <h2 className="home-title">About Mac's Party Productions</h2>
                    <p className="home-content">
                        As a family-friendly DJ service, we specialize in catering to events of all sizes. 
                        Whether you're looking for party vibes with or without lights, karaoke sessions, or even an 
                        outdoor movie experience with a 20-foot inflatable screen, we've got you covered. 
                        Thinking of adding a festive touch? Let us bring Santa for picture sessions. With sound systems 
                        that fit both large venues and intimate gatherings, our packages are tailored to suit any event.
                    </p>
                </div>

                {/* Music selection section */}
                <div className="text-container">
                    <h2 className="home-title">Music Selection</h2>
                    <p className="home-content">
                        Our music library is vast and diverse. We don't limit ourselves to specific genres – 
                        we play what YOU want. From the latest hits to timeless classics, we offer the radio versions 
                    </p>
                </div>

                {/* Trust and reliability section */}
                <div className="text-container">
                    <h2 className="home-title">Trust and Reliability</h2>
                    <p className="home-content">
                        At Mac's Party Productions, your event is our top priority. That's why we are <a href='https://portal.nextinsurance.com/public/certificates/live-certificate/5f505fb6cab5d9fdf1757e84fab4dff6?fbclid=IwAR2oCDztyVObtsI24tKekdVk0jejqdSu2nEWE6RIzRCtMCFRQMnl-gBYmAA'><b>insured up to 2 million 
                        dollars</b></a> and have passed rigorous background checks. Your peace of mind is paramount to us.
                    </p>
                </div>

                {/* Services list section */}
                <div className="text-container">
                    <h2 className="home-title">Our Services</h2>
                    <ul className="home-content">
                        <li>Corporate Events</li>
                        <li>DJ</li>
                        <li>Karaoke</li>
                        <li>Wedding</li>
                        <li>Prom</li>
                        <li>Sweet 16</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Home;
