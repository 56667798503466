import React from 'react';
import './package.css';
// Importing icons for each package
import silverIcon from '../images/silver-icon.png';
import goldIcon from '../images/gold-icon.png';
import platinumIcon from '../images/platinum-icon.png';

// Component for packaging options
function Package() {
    return (
        <div className="packages-container">
            <h1>Pricing Packages</h1>
            <div className="packages">
                {/* Silver Package Card */}
                <div className="silver-bg">
                    <div className="package-card silver">
                        <div className="package-icon-container">
                            <img src={silverIcon} alt="Silver Package Icon" className="package-icon"/>
                        </div>
                        <div className="package-pricing">
                            <h3>$200 per hour</h3>
                            <h4>(Minimum of 3 hours)</h4>
                        </div>
                        <div className="package-divider">
                            {/* Divider line for aesthetic separation */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="225" height="4" viewBox="0 0 279 4" fill="none">
                            <path d="M2 2L277 2" stroke="black" stroke-width="3" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div className="package-details">
                            {/* List of silver package offerings */}
                            <ul>
                                <li>For Corporate Events, Weddings, Quinceaneras</li>
                                <li>DJ with State of the Art Sound System</li>
                                <li>MC to Host the Event</li>
                                <li>2 Wireless Mics for Announcements, Speeches, and Special Moments</li>
                                <li>Smoke Machine and Karaoke Setup for Enhanced Entertainment</li>
                                <li>Lights</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                {/* Gold Package Card */}
                <div className="gold-bg">
                    <div className="package-card gold">
                        <div className="package-icon-container">
                            <img src={goldIcon} alt="Gold Package Icon" className="package-icon"/>
                        </div>
                        <div className="package-pricing">
                            <h3>$125 per hour</h3>
                            <h4>(Minimum of 3 hours)</h4>
                        </div>
                        <div className="package-divider">
                            {/* Divider line for aesthetic separation */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="225" height="4" viewBox="0 0 279 4" fill="none">
                            <path d="M2 2L277 2" stroke="black" stroke-width="3" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div className="package-details">
                            {/* List of gold package offerings */}
                            <ul>
                                <li>DJ and Sound System</li>
                                <li>2 Wireless Mics</li>
                                <li>Customizable song list for tailored playlist</li>
                                <li>Live song requests accommodated during the event</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Platinum Package Card */}
                <div className="platinum-bg">
                    <div className="package-card platinum">
                        <div className="package-icon-container">
                            <img src={platinumIcon} alt="Platinum Package Icon" className="package-icon"/>
                        </div>
                        <div className="package-pricing">
                            <h3>$150 per hour </h3>
                            <h4>(Minimum of 3 hours)</h4>
                        </div>
                        <div className="package-divider">
                            {/* Divider line for aesthetic separation */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="225" height="4" viewBox="0 0 279 4" fill="none">
                            <path d="M2 2L277 2" stroke="black" stroke-width="3" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div className="package-details">
                            {/* List of platinum package offerings */}
                            <ul>
                                <li>DJ / Optional Karaoke</li>
                                <li>4000 Watt Sound System small footprint</li>
                                <li>Lights</li>
                                <li>Customizable song list for tailored playlist</li>
                                <li>Live song requests accommodated during the event</li>
                                <li>2 Wireless Mics</li>
                                <li>MC services for an additional $75.00 per hour</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Package;
