import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Global styles
import App from './App'; // Main app component
import reportWebVitals from './reportWebVitals';

// Root element for React application
const root = ReactDOM.createRoot(document.getElementById('root'));
// Render App component inside the root element
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional performance reporting
reportWebVitals();
