import React from 'react';
import './footer.css';

// Footer functional component
const Footer = () => {
    return (
        <footer className="custom-footer">
            {/* Social media logos with links */}
            <div className="footer-logos">
                {/* Facebook Logo with link */}
                <a href="https://www.facebook.com/people/Mac-Party-Productions/100084974506729/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M15 2.55005C8.125 2.55005 2.5 8.16255 2.5 15.075C2.5 21.325 7.075 26.5125 13.05 27.45V18.7H9.875V15.075H13.05V12.3125C13.05 9.17505 14.9125 7.45005 17.775 7.45005C19.1375 7.45005 20.5625 7.68755 20.5625 7.68755V10.775H18.9875C17.4375 10.775 16.95 11.7375 16.95 12.725V15.075H20.425L19.8625 18.7H16.95V27.45C19.8956 26.9849 22.5778 25.4819 24.5124 23.2126C26.4471 20.9433 27.5067 18.0571 27.5 15.075C27.5 8.16255 21.875 2.55005 15 2.55005Z" fill="white"/>
                    </svg>
                </a>
                {/* Instagram Logo with link */}
                <a href="https://www.instagram.com/macspartyproductions/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M9.75 2.5H20.25C24.25 2.5 27.5 5.75 27.5 9.75V20.25C27.5 22.1728 26.7362 24.0169 25.3765 25.3765C24.0169 26.7362 22.1728 27.5 20.25 27.5H9.75C5.75 27.5 2.5 24.25 2.5 20.25V9.75C2.5 7.82718 3.26384 5.98311 4.62348 4.62348C5.98311 3.26384 7.82718 2.5 9.75 2.5ZM9.5 5C8.30653 5 7.16193 5.47411 6.31802 6.31802C5.47411 7.16193 5 8.30653 5 9.5V20.5C5 22.9875 7.0125 25 9.5 25H20.5C21.6935 25 22.8381 24.5259 23.682 23.682C24.5259 22.8381 25 21.6935 25 20.5V9.5C25 7.0125 22.9875 5 20.5 5H9.5ZM21.5625 6.875C21.9769 6.875 22.3743 7.03962 22.6674 7.33265C22.9604 7.62567 23.125 8.0231 23.125 8.4375C23.125 8.8519 22.9604 9.24933 22.6674 9.54235C22.3743 9.83538 21.9769 10 21.5625 10C21.1481 10 20.7507 9.83538 20.4576 9.54235C20.1646 9.24933 20 8.8519 20 8.4375C20 8.0231 20.1646 7.62567 20.4576 7.33265C20.7507 7.03962 21.1481 6.875 21.5625 6.875ZM15 8.75C16.6576 8.75 18.2473 9.40848 19.4194 10.5806C20.5915 11.7527 21.25 13.3424 21.25 15C21.25 16.6576 20.5915 18.2473 19.4194 19.4194C18.2473 20.5915 16.6576 21.25 15 21.25C13.3424 21.25 11.7527 20.5915 10.5806 19.4194C9.40848 18.2473 8.75 16.6576 8.75 15C8.75 13.3424 9.40848 11.7527 10.5806 10.5806C11.7527 9.40848 13.3424 8.75 15 8.75ZM15 11.25C14.0054 11.25 13.0516 11.6451 12.3483 12.3483C11.6451 13.0516 11.25 14.0054 11.25 15C11.25 15.9946 11.6451 16.9484 12.3483 17.6517C13.0516 18.3549 14.0054 18.75 15 18.75C15.9946 18.75 16.9484 18.3549 17.6517 17.6517C18.3549 16.9484 18.75 15.9946 18.75 15C18.75 14.0054 18.3549 13.0516 17.6517 12.3483C16.9484 11.6451 15.9946 11.25 15 11.25Z" fill="white"/>
                    </svg>
                </a>
                {/* YouTube Logo with link */}
                {/* <a href="https://www.youtube.com/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M12.5 18.75L18.9875 15L12.5 11.25V18.75ZM26.95 8.9625C27.1125 9.55 27.225 10.3375 27.3 11.3375C27.3875 12.3375 27.425 13.2 27.425 13.95L27.5 15C27.5 17.7375 27.3 19.75 26.95 21.0375C26.6375 22.1625 25.9125 22.8875 24.7875 23.2C24.2 23.3625 23.125 23.475 21.475 23.55C19.85 23.6375 18.3625 23.675 16.9875 23.675L15 23.75C9.7625 23.75 6.5 23.55 5.2125 23.2C4.0875 22.8875 3.3625 22.1625 3.05 21.0375C2.8875 20.45 2.775 19.6625 2.7 18.6625C2.6125 17.6625 2.575 16.8 2.575 16.05L2.5 15C2.5 12.2625 2.7 10.25 3.05 8.9625C3.3625 7.8375 4.0875 7.1125 5.2125 6.8C5.8 6.6375 6.875 6.525 8.525 6.45C10.15 6.3625 11.6375 6.325 13.0125 6.325L15 6.25C20.2375 6.25 23.5 6.45 24.7875 6.8C25.9125 7.1125 26.6375 7.8375 26.95 8.9625Z" fill="white"/>
                    </svg>
                </a> */}
                {/* Twitter Logo with link */}
                {/* <a href="https://www.twitter.com/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M28.075 7.5C27.1125 7.9375 26.075 8.225 25 8.3625C26.1 7.7 26.95 6.65 27.35 5.3875C26.3125 6.0125 25.1625 6.45 23.95 6.7C22.9625 5.625 21.575 5 20 5C17.0625 5 14.6625 7.4 14.6625 10.3625C14.6625 10.7875 14.7125 11.2 14.8 11.5875C10.35 11.3625 6.38749 9.225 3.74999 5.9875C3.28749 6.775 3.02499 7.7 3.02499 8.675C3.02499 10.5375 3.96249 12.1875 5.41249 13.125C4.52499 13.125 3.69999 12.875 2.97499 12.5C2.97499 12.5 2.97499 12.5 2.97499 12.5375C2.97499 15.1375 4.82499 17.3125 7.27499 17.8C6.82499 17.925 6.34999 17.9875 5.86249 17.9875C5.52499 17.9875 5.18749 17.95 4.86249 17.8875C5.53749 20 7.49999 21.575 9.86249 21.6125C8.03749 23.0625 5.72499 23.9125 3.19999 23.9125C2.77499 23.9125 2.34999 23.8875 1.92499 23.8375C4.29999 25.3625 7.12499 26.25 10.15 26.25C20 26.25 25.4125 18.075 25.4125 10.9875C25.4125 10.75 25.4125 10.525 25.4 10.2875C26.45 9.5375 27.35 8.5875 28.075 7.5Z" fill="white"/>
                    </svg>
                </a> */}
            </div>
            {/* Footer text content */}
            <div className="footer-text">
                <p>Copyright © 2022, All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
