import React from 'react';
import './additional_service.css';


// The AdditionalService functional component
function AdditionalService() {
    return (
        <section id="additional-services" className="additional-services">
                <div className="container">
                    <h2>Additional Services</h2>
                    
                    {/* Santa Service */}
                    <div className="text-container">
                        <div className="service-item">
                            <h3>Seasonal Santa Service</h3>
                            <p>During the festive months of November and December, add a sprinkle of magic to your events with our exclusive Santa service. Perfect for holiday parties, corporate events, and special engagements.</p>
                        </div>
                    </div>

                    {/* Photo Booths */}\
                    <div className="text-container">
                        <div className="service-item">
                            <h3>Photo Booth Rentals</h3>
                            <p>Want to capture the moments at your next event? Rent one of our photo booths.</p> <p> Two styles available</p>
                            <ul>
                                <li><strong>360° Photo Booth:</strong> 
                                <p>Surround your guests with fun and capture every angle with our immersive 360° photo booth experience.</p></li>
                                <li><strong>Digital Photo Booth:</strong> <p>Sleek and social media-ready, our digital photo booth allows instant sharing to capture and spread your memorable moments.</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
        </section>
    );
}

export default AdditionalService;
