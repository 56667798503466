import React from 'react';
import NavBar from './components/navbar';
import Home from './components/home';
import Package from './components/package';
import Contact from './components/contact';
import Gallery from './components/gallery';
import Footer from './components/footer';
import AdditionalService from './components/additional_service';
// Import any other required components here

// Main App component which assembles all the page sections
function App() {
  return (
      <div className="App">
          {/* Navigation Bar */}
          <NavBar />
          {/* Home section */}
          <div id="home">
              <Home />
          </div>
          {/* Gallery section */}
          <div id="gallery">
              <Gallery />
          </div>
          {/* Package section */}
          <div id="package">
              <Package />
          </div>
          {/* Additional Service section */}
            <div id="additional-service">
                <AdditionalService />
            </div>
          {/* Contact section */}
          <div id="contact">
              <Contact />
          </div>
          {/* Footer */}
          <Footer />
      </div>
  );
}

export default App;
