import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./gallery.css";

const Carousel = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState("right");


  // Load and shuffle images on mount
 useEffect(() => {
  const selectedIndices = new Set();
  const imagePaths = [];

  while (imagePaths.length < 10) {
    const randomIndex = Math.floor(Math.random() * 57) + 1;
    if (!selectedIndices.has(randomIndex)) {
      imagePaths.push(`/gallery/image_${randomIndex}.png`);
      selectedIndices.add(randomIndex);
    }
  }

  setImages(imagePaths);
}, []);

  // Animation variants for the sliding effect
  const slideVariants = {
    hiddenRight: { x: "100%", opacity: 0 },
    hiddenLeft: { x: "-100%", opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 1 } },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.5 } },
  };

  // Sets up an interval for automatic sliding
  useEffect(() => {
    const autoSlideInterval = setInterval(() => {
      setDirection("right");
      setCurrentIndex(prevIndex =>
        prevIndex + 1 === images.length ? 0 : prevIndex + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(autoSlideInterval); // Cleanup interval on unmount
  }, [images]);

  // Carousel component layout
  return (
    <div className="carousel">
      <div className="carousel-images">
        <AnimatePresence>
          {images.length > 0 && (
            <motion.img
              key={currentIndex}
              src={images[currentIndex]}
              initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
              animate="visible"
              exit="exit"
              variants={slideVariants}
              // Make sure the image is styled properly with a className or inline styles if needed
            />
          )}
        </AnimatePresence>
      </div>
      <div className="carousel-indicator">
        {images.map((_, index) => (
          <motion.div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></motion.div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
